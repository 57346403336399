import React from "react";
import posed, { PoseGroup } from "react-pose";

const TIMEOUT = 0;
const DURATION = 800;

let td;

const containerStyle = {
  height: "100vh",
  overflowY: "scroll",
  overflowX: "hidden"
};

class Transition extends React.PureComponent {
  state = {
    currentPage: ""
  };

  componentDidMount() {
    if (location) {
      this.setState({
        currentPage: location.pathname.slice(1)
      });
    }
  }

  getCurrentPage = () => {
    let { currentPage } = this.state;

    if (location && location.pathname) {
      currentPage = location.pathname.slice(1);
    }

    let parts = currentPage.split("/");

    if (parts.length > 1) {
      currentPage = `${parts[0]}-entry`;
    }

    return currentPage;
  };

  getFromPage = () => {
    const { location = {} } = this.props;
    let { fromPage = undefined } = location.state || {};

    if (fromPage) {
      let parts = fromPage.split("/");

      if (parts.length > 1) {
        fromPage = `${parts[0]}-entry`;
      }
    }

    return fromPage;
  };

  render() {
    const fromPage = this.getFromPage();
    const currentPage = this.getCurrentPage();

    let transitionDirection = "left";

    // console.log(`${fromPage} -> ${currentPage}`);

    if (fromPage !== undefined && fromPage !== currentPage) {
      if (currentPage === "") transitionDirection = "right";
      else if (currentPage === "posters") transitionDirection = "left";
      else if (currentPage === "about") {
        if (fromPage === "") transitionDirection = "left";
        else if (fromPage === "posters") transitionDirection = "right";
      }
    } else if (fromPage === "projects-entry" || fromPage === "posters-entry") {
      let { direction = "Next" } = this.props.location.state;
      if (direction === "Previous") transitionDirection = "right";
    }

    td = transitionDirection;

    // console.log("transitionDirection ", td);

    const transition = {
      enter: {
        delay: TIMEOUT,
        delayChildren: TIMEOUT,
        transition: { ease: "easeInOut", duration: DURATION },
        x: 0,
        opacity: 1
      },
      preEnter: {
        delay: TIMEOUT,
        delayChildren: TIMEOUT,
        transition: { ease: "easeInOut", duration: DURATION },
        x: () => {
          if (td === "none") return 0;
          else return `${(td === "left" ? 1 : -1) * 100}vw`;
        }
      },
      exit: {
        transition: { ease: "easeInOut", duration: DURATION },
        x: () => {
          if (td === "none") return 0;
          else return `${(td === "left" ? -1 : 1) * 100}vw`;
        }
      }
    };

    const RoutesContainer = posed.div(transition);

    return (
      <PoseGroup preEnterPose="preEnter">
        <RoutesContainer key={location.pathname} style={containerStyle} data-scroll>
          {this.props.children}
        </RoutesContainer>
      </PoseGroup>
    );
  }
}

export default Transition;
