module.exports = [{
      plugin: require('/opt/build/repo/web/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"typekit":{"id":"qxi0rvh"}},
    },{
      plugin: require('/opt/build/repo/web/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-149512442-1","anonymize":true,"respectDNT":true},
    },{
      plugin: require('/opt/build/repo/web/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
